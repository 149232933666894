<template>
  <div class="users">
    <div class="panel-title">
      {{ $t("MFAManagement") }}
    </div>

    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-form @submit.native.prevent>
          <div class="searchInput">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item :label="$t('enterpriseCode')">
                  <el-input
                    v-model="searchForm.enterpriseCode"
                    :placeholder="$t('enterpriseCode')"
                    clearable
                    @keyup.enter.native="getList()"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('enterpriseName')">
                  <el-input
                    v-model="searchForm.enterpriseName"
                    :placeholder="$t('enterpriseName')"
                    clearable
                    @keyup.enter.native="getList()"
                  >
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24" style="text-align: right;">
                <el-button :disabled="loading" class="confirm" @click="getList()" style="margin-bottom: 20px;"
                  >{{ $t("search") }}
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
    <div class="result-background" style="margin-top:20px;">
      <div class="result-table" v-loading="loading">
        <el-table :data="tableData">
          <el-table-column prop="enterpriseCode" :label="$t('enterpriseCode')"> </el-table-column>
          <el-table-column prop="enterpriseName" :label="$t('enterpriseName')"> </el-table-column>
          <el-table-column prop="forceMfa" :label="$t('forceMFA')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-switch v-model="scope.row.forceMfa" @change="forceMfaChange(scope.row)"> </el-switch>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          layout=" prev, pager, next, sizes, total"
          :total="total"
          :page-size="currentPageSize"
          :page-sizes="[10, 15, 20]"
          :current-page="currentPage"
          @current-change="currentChange"
          @size-change="sizeChange"
          class="common-pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntMFAList, updateEntMFAConfig } from "api/user";
import pagination from "@/mixins/pagination";
export default {
  mixins: [pagination],
  name: "MFAManage",
  data() {
    return {
      loading: false,
      tableData: [],
      searchForm: {
        enterpriseCode: "",
        enterpriseName: ""
      }
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    forceMfaChange(e) {
      this.$confirm(this.$t("confirm") + "?", "", {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel")
        // type: "info"
      })
        .then(() => {
          this.loading = true;
          updateEntMFAConfig({
            entId: e.enterpriseCode,
            forceMfa: e.forceMfa
          }).then(res => {
            console.log(res);
            this.loading = false;
            if (res.resultStatus === "Success" && res.statusCode === 200) {
              this.$message.success(this.$t("success"));
            }
          });
        })
        .catch(() => {
          e.forceMfa = !e.forceMfa;
          this.loading = false;
        });
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      getEntMFAList({
        enterpriseCode: this.searchForm.enterpriseCode,
        enterpriseName: this.searchForm.enterpriseName,
        pageSize,
        page: pageIndex
      }).then(response => {
        this.loading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.tableData = response.result;
          this.total = response.totalCount;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
